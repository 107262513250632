<template >
    <div class="justify-content-between align-items-center content-sidebar px-1 py-1 modal-bottom modal-top">
        <b-form>
            <b-row>
                <b-col cols="12">
                    <b-form-group :label="$t('IntegrationPage.Type')" label-for="type">
                        <b-form-select v-model="typeConnection" id="authentication-type"
                            :options="listagemTiposConexoes" @input="changeModal()">
                        </b-form-select>
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-form-group :label="$t('IntegrationPage.NameConection')" label-for="name"
                        :invalid-feedback="invalidNameState ? $t('NameInvalid') : $t('RequiredField')">
                        <b-form-input id="name" v-model="connectionObject.name" :placeholder="$t('TypeHere')"
                            :state="stateName" />
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="6" lg="6" xl="6">
                    <b-form-group :label="$t('IntegrationPage.TenantId')" label-for="base-uri"
                        :invalid-feedback="$t('RequiredField')">
                        <b-form-input id="base-uri" v-model="connectionObject.tenantId"
                            placeholder="a17b01f3-1d9e-5f5e-97db-c44c6970a0da" :state="stateTenant" />
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="6" lg="6" xl="6">
                    <b-form-group :label="$t('IntegrationPage.ApplicationId')" label-for="authentication-type"
                        :invalid-feedback="$t('RequiredField')">
                        <b-form-input id="base-uri" v-model="connectionObject.applicationId"
                            placeholder="5d2311b1-0123-3912-884g-da40c7641525" :state="stateApplication" />
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="6" lg="6" xl="6">
                    <b-form-group :label="$t('IntegrationPage.AuthKey')" label-for="base-uri"
                        :invalid-feedback="$t('RequiredField')">
                        <b-form-input id="base-uri" v-model="connectionObject.authKey"
                            placeholder="J4d8Q~6o4fmw7YdfhgGCgg5VRtro8tqrt6IaocrF" :state="stateAuthKey" />
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="6" lg="6" xl="6">
                    <b-form-group :label="$t('IntegrationPage.SubscriptionId')" label-for="authentication-type"
                        :invalid-feedback="$t('RequiredField')">
                        <b-form-input id="base-uri" v-model="connectionObject.subscriptionId"
                            placeholder="gg9574fd-g1gb-4u40-e159-e123addf7ad4" :state="stateSubscription" />
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-form-group :label="$t('IntegrationPage.Region')" label-for="authentication-type"
                        :invalid-feedback="$t('RequiredField')">
                        <b-form-input id="base-uri" v-model="connectionObject.region" placeholder="Brazil South"
                            :state="stateRegion" />
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="12" class="d-flex justify-content-end save-button">
                    <b-form-group>
                        <b-button v-if="!sendingForm" variant="primary" class="form-control"
                            @click="createConnection()">
                            {{ $t('IntegrationPage.Save') }}
                        </b-button>

                        <b-button v-if="sendingForm" variant="primary" disabled class="form-control loading"
                            label-for="value">
                            <b-spinner small />
                            {{ $t('IntegrationPage.Sending') }} ...
                        </b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>

    </div>
</template>
  
<script>
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { TheMask } from 'vue-the-mask'
import ImgDeleteIcon from '@/assets/images/pages/icon-delete.svg'
import ImgAddIcon from '@/assets/images/pages/icon-add.svg'
import ImgEditIcon from '@/assets/images/pages/edit.svg'
import Eye from '@/assets/images/icons/eye.svg'
import EyeOff from '@/assets/images/icons/eye-off.svg'

import {
    BAvatar, BListGroup, BListGroupItem, BCardText, BForm, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BButton, BSpinner, BRow, BCol, BFormTextarea, BBadge, BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'

export default {
    components: {
        BAvatar,
        BListGroup,
        BListGroupItem,
        BCardText,
        BForm,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BFormSelectOption,
        BButton,
        BSpinner,
        BRow,
        BCol,
        BFormTextarea,
        BBadge,
        ImgAddIcon,
        ImgDeleteIcon,
        ImgEditIcon,
        TheMask,
        flatPickr,
        ToastificationContent,
        BInputGroup,
        BInputGroupAppend,
        Eye,
        EyeOff
    },
    directives: {
        Ripple,
    },
    props: {
        connectionId: {
            type: String,
        },
    },
    watch: {
        connectionId: function (newVal, oldVal) {
            this.getConnectionById(newVal)
        }
    },
    data() {
        return {
            stateName: null,
            stateTenant: null,
            stateApplication: null,
            stateAuthKey: null,
            stateSubscription: null,
            stateRegion: null,
            invalidNameState: null,
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            connectionObject: {
                name: null,
                tenantId: null,
                applicationId: null,
                authKey: null,
                subscriptionId: null,
                region: null,
            },
            sendingForm: false,
            propertyName: null,
            typeConnection: 5,
            type: 'Data Factory',
            listagemTiposConexoes: [
                { text: 'REST', value: 1 },
                { text: 'SQL Server', value: 2 },
                { text: 'SAP (RFC)', value: 3 },
                { text: 'Excel (.csv)', value: 4 },
                { text: 'Azure Data Factory', value: 5 }
            ],
            authenticationList: [
                {
                    key: '',
                    value: '',
                    stateKey: null,
                    stateValue: null
                }
            ],
        }
    },
    props: {
        listagemContexto: {
            type: Array,
        },
    },
    methods: {
        changeModal() {
            this.$emit('changeModal', this.typeConnection)
        },
        validaNomeFonte() {
            let fonteExistente = this.listagemContexto.filter(item => item.name == this.connectionObject.name && item.type_id == "6")
            if (fonteExistente.length > 0) {
                return true
            }
            return false
        },
        validaCampos() {
            this.stateName = this.connectionObject.name && !this.validaNomeFonte() ? null : false;
            this.invalidNameState = this.validaNomeFonte();
            this.stateTenant = this.connectionObject.tenantId ? null : false;
            this.stateApplication = this.connectionObject.applicationId ? null : false;
            this.stateAuthKey = this.connectionObject.authKey ? null : false;
            this.stateSubscription = this.connectionObject.subscriptionId ? null : false;
            this.stateRegion = this.connectionObject.region ? null : false;
        },
        createConnection() {
            this.validaCampos();

            if (this.camposValidos()) {
                this.sendingForm = true;
               
                this.$http.post(`/api/${this.$router.currentRoute.params.prefix}/connection`, {
                    site_guid: this.currentSite.guid,
                    name: this.connectionObject.name,
                    tenant_id: this.connectionObject.tenantId,
                    application_id: this.connectionObject.applicationId,
                    auth_key: this.connectionObject.authKey,
                    subscription_id: this.connectionObject.subscriptionId,
                    region: this.connectionObject.region,
                    type: this.type,
                    id: null
                })
                    .then(response => {
                        this.sendingForm = false
                        this.$emit('refetch-data')
                        this.$emit('show-message')
                        this.showToast(this.$t('IntegrationPage.success'), 'SmileIcon', this.$t('IntegrationPage.createConectionSuccess'), 'success')
                    })
                    .catch((error => { this.sendingForm = false; this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger') }))
            }
        },
        showToast(title, icon, text, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: icon,
                    text: text,
                    variant,
                },
            })
        },
        camposValidos() {
            if (this.stateName !== false
                && this.stateTenant !== false
                && this.stateApplication !== false
                && this.stateAuthKey !== false
                && this.stateSubscription !== false
                && this.stateRegion !== false)
                return true;

            return false;
        }
    }
}
</script>
<style scoped>
.button-action-dinamic-data {
    margin-top: 23px;
}

.label-key,
.label-value {
    margin-bottom: 3px;
    color: #5e5873;
    font-size: 0.857rem;
}

.icon-edit-key,
.icon-edit-value {
    margin-left: 7px;
}


.btn.loading {
    width: auto !important;
}

.is-invalid {
    background-image: none !important;
}
</style>