<template >
  <div class="justify-content-between align-items-center content-sidebar px-1 py-1 modal-bottom modal-top">
        <b-form>
            <b-row>

                <b-col md="12">
                  <b-form-group
                  :label="$t('IntegrationPage.Type')"
                  label-for="type"
                  >
                  <b-form-select
                  v-model="typeConnection"
                  id="authentication-type"
                  :options="listagemTiposConexoes"
                  @input="changeModal()"
                  disabled
                  >
                  </b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                  :label="$t('IntegrationPage.NameConection')"
                  label-for="name"
                  :invalid-feedback="invalidNameState ?$t('NameInvalid') :$t('RequiredField')"
                  >
                  <b-form-input
                  id="name"
                  v-model="name"
                  placeholder="SAP Ambiente de Produção"
                  :state="stateNameConnection"
                  />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                    <b-form-group
                    :label="$t('IntegrationPage.Destiny')"
                    label-for="destination"
                    :invalid-feedback="$t('RequiredField')"
                    >
                    <b-form-input
                    placeholder="SAP"
                    id="destination"
                    v-model="destination"
                    :state="stateDestination"
                    />
                    </b-form-group>
                </b-col>

                <b-col md="4">
                    <b-form-group
                    :label="$t('IntegrationPage.Server')"
                    label-for="server"
                    :invalid-feedback="$t('RequiredField')"
                    >
                    <b-form-input
                    placeholder="127.0.0.1"
                    id="server"
                    v-model="server"
                    :state="stateServer"
                    />
                    </b-form-group>
                </b-col>

                <b-col md="4">
                    <b-form-group
                    :label="$t('IntegrationPage.Gateway')"
                    label-for="gateway"
                    :invalid-feedback="$t('RequiredField')"
                    >
                    <b-form-input
                    placeholder="127.0.0.1"
                    id="gateway"
                    v-model="gateway"
                    :state="stateGateway"
                    />
                    </b-form-group>
                </b-col>

                <b-col md="4">
                    <b-form-group
                    :label="$t('IntegrationPage.SystemNumber')"
                    label-for="system-number"
                    :invalid-feedback="$t('RequiredField')"
                    >
                    <b-form-input
                    placeholder="00"
                    id="system-number"
                    v-model="systemNumber"
                    :state="stateSystemNumber"
                    />
                    </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    :label="$t('IntegrationPage.User')"
                    label-for="user"
                    :invalid-feedback="$t('RequiredField')"
                    >
                      <b-form-input
                        v-model="user"
                        id="user"
                        :placeholder="$t('TypeHere')"
                        :state="stateUser"
                        autocomplete="off"
                        onfocus="this.removeAttribute('readonly');"
                      />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    :label="$t('IntegrationPage.Password')"
                    label-for="password"
                    :invalid-feedback="$t('RequiredField')"
                    >
                    <b-input-group :class="{'is-invalid': statePassword === false}">
                      <b-form-input
                          class="border-right-0"  
                          :type="passwordType"
                          v-model="password"
                          id="password"
                          placeholder="******"
                          :state="statePassword" 
                          autocomplete="off"
                          onfocus="this.removeAttribute('readonly');"/>
                      <b-input-group-append is-text style="cursor: pointer;" @click="showPassword">
                        <eye v-if="passwordType === 'password'" />
                        <eye-off v-else />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    :label="$t('IntegrationPage.Client')"
                    label-for="client"
                    :invalid-feedback="$t('RequiredField')"
                    >
                      <b-form-input
                        v-model="client"
                        id="client"
                        placeholder="500"
                        :state="stateClient"
                      />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    :label="$t('IntegrationPage.Language')"
                    label-for="language"
                    :invalid-feedback="$t('RequiredField')"
                    >
                      <b-form-input
                        v-model="language"
                        id="language"
                        placeholder="PT"
                        :state="stateLanguage"
                      />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group
                    :label="$t('IntegrationPage.PoolSize')"
                    label-for="pool-size"
                    :invalid-feedback="$t('RequiredField')"
                    >
                      <b-form-input
                        v-model="poolSize"
                        id="pool-size"
                        placeholder="55"
                        :state="statePoolsize"
                      />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group
                    :label="$t('IntegrationPage.MaxPoolSize')"
                    label-for="max-pool-size"
                    :invalid-feedback="$t('RequiredField')"
                    >
                      <b-form-input
                        v-model="maxPoolSize"
                        id="max-pool-size"
                        placeholder="10"
                        :state="stateMaxPoolsize"
                      />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group
                    :label="$t('IntegrationPage.Timeout')"
                    label-for="idleTime"
                    :invalid-feedback="$t('RequiredField')"
                    >
                      <b-form-input
                        type="number"
                        v-model="idleTime"
                        id="idleTime"
                        placeholder="3600"
                        :state="stateIdleTime"
                      />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    :label="$t('IntegrationPage.ConnectionSNC')"
                    label-for="sncMode"
                    >
                      <b-form-checkbox
                        v-model="sncMode"
                        switch
                        :options="[{text: $t('Yes'), value: 1}, {text: $t('No'), value: 0}]"
                        id="sncMode"
                      />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group
                    :label="$t('IntegrationPage.LibrarySNC')"
                    label-for="sncLib"
                    v-if="sncMode == 1"
                    :invalid-feedback="$t('RequiredField')"
                    >
                      <b-form-input
                        v-model="sncLib"
                        id="sncLib"
                        :placeholder="$t('TypeHere')"
                        :state="stateSncLib"
                      />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group
                    :label="$t('IntegrationPage.MyNameSNC')"
                    label-for="snc-my-name"
                    v-if="sncMode == 1"
                    :invalid-feedback="$t('RequiredField')"
                    >
                      <b-form-input
                        v-model="sncMyName"
                        id="snc-my-name"
                        :placeholder="$t('TypeHere')"
                        :state="stateSncMyName"
                      />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group
                    :label="$t('IntegrationPage.PartnerNameSNC')"
                    label-for="snc-partner-name"
                    v-if="sncMode == 1"
                    :invalid-feedback="$t('RequiredField')"
                    >
                      <b-form-input
                        v-model="sncPartnerName"
                        id="snc-partner-name"
                        :placeholder="$t('TypeHere')"
                        :state="stateSncPartnerName"
                      />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    label="SNC QOP"
                    label-for="snc-qop"
                    v-if="sncMode == 1"
                    :invalid-feedback="$t('RequiredField')"
                    >
                      <b-form-input
                        v-model="sncQOP"
                        id="snc-qop"
                        :placeholder="$t('TypeHere')"
                        :state="stateSncQop"
                      />
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group
                    :label="$t('IntegrationPage.SystemId')"
                    label-for="system-id"
                    v-if="sncMode == 1"
                    :invalid-feedback="$t('RequiredField')"
                    >
                      <b-form-input
                        v-model="sysId"
                        id="system-id"
                        :placeholder="$t('TypeHere')"
                        :state="stateSysId"
                      />
                  </b-form-group>
                </b-col>

                <b-col md="12" class="d-flex justify-content-end save-button">
                    <b-form-group>
                        <b-button v-if="!sendingForm"
                        variant="primary"
                        class="form-control"
                        @click="createConnection()"
                        >
                        {{ $t('IntegrationPage.Save') }}
                        </b-button>

                    <b-button v-if="sendingForm"
                        variant="primary"
                        disabled
                        class="form-control loading"
                    >
                        <b-spinner small />
                        {{ $t('IntegrationPage.Sending') }} ...
                    </b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>

  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { TheMask } from 'vue-the-mask'
import Eye from '@/assets/images/icons/eye.svg'
import EyeOff  from '@/assets/images/icons/eye-off.svg'

import {
  BAvatar, BListGroup, BListGroupItem, BCardText, BForm, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BButton, BSpinner, BRow, BCol, BFormTextarea, BFormCheckbox, BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormTextarea,
    BFormCheckbox,
    TheMask,
    flatPickr,
    ToastificationContent,
    BInputGroup, 
    BInputGroupAppend,
    Eye,
    EyeOff
  },
  directives: {
    Ripple,
  },
  props:{
    connectionId: {
      type: String,
    },
    listagemContexto: {
      type: Array,
    },
  },
  mounted() {
    this.getConnectionById(this.connectionId)
  },
  data() {
    return {
      currentSite: JSON.parse(localStorage.getItem('currentSite')),
      type: 'SAP',
      name: null,
      destination: null,
      server: null,
      gateway: null,
      systemNumber: null,
      user: null,
      password: null,
      client: null,
      language: null,
      poolSize: null,
      maxPoolSize: null,
      idleTime: null,
      sncMode: 0,
      sncLib: null,
      sncMyName: null,
      sncPartnerName: null,
      sysId: null,
      sncQOP: null,
      sendingForm: false,
      selected: null,
      typeConnection: 3,
      listagemTiposConexoes: [
        {text: 'REST', value: 1},  
        {text: 'SQL Server', value: 2},  
        {text: 'SAP (RFC)', value: 3},  
        {text: 'Excel (.csv)', value: 4},  
      ],
      invalidNameState:null,
      stateNameConnection:null,
      stateDestination:null,
      stateServer:null,
      stateGateway:null,
      stateSystemNumber:null,
      stateUser:null,
      statePassword:null,
      stateClient:null,
      stateLanguage:null,
      statePoolsize:null,
      stateMaxPoolsize:null,
      stateIdleTime:null,
      stateSncLib:null,
      stateSncMyName:null,
      stateSncPartnerName:null,
      stateSncQop:null,
      stateSysId:null,
      contexto:[],
      passwordType: 'password'
    }
  },
  methods: {
    validaNomeFonte(){
      if(this.name != this.contexto.name){
        let fonteExistente = this.listagemContexto.filter(item => item.name == this.name && item.type_id == "3")
        if (fonteExistente.length > 0){
          return true
        }
        return false
      }
    },
    validaCampos(){
      this.invalidNameState = this.validaNomeFonte()
      this.stateBaseURL = this.baseUri ? null : false
      this.stateNameConnection = this.name && !this.validaNomeFonte() ? null : false
      this.stateDestination = this.destination ? null : false
      this.stateServer = this.server ? null : false
      this.stateGateway = this.gateway ? null : false
      this.stateSystemNumber = this.systemNumber ? null : false
      this.stateUser = this.user ? null : false
      this.statePassword = this.password ? null : false
      this.stateClient = this.client ? null : false
      this.stateLanguage = this.language ? null : false
      this.statePoolsize = this.poolSize ? null : false
      this.stateMaxPoolsize = this.maxPoolSize ? null : false
      this.stateIdleTime = this.idleTime ? null : false
      if(this.sncMode == '1' ){
        this.stateSncLib = this.sncLib ? null : false
        this.stateSncMyName = this.sncMyName ? null : false
        this.stateSncPartnerName = this.sncPartnerName ? null : false
        this.stateSncQop = this.sncQOP ? null : false
        this.stateSysId = this.sysId ? null : false
      }
      
    },
    camposValidos(){
      if ( this.stateNameConnection != false && this.stateDestination != false && this.stateServer != false && this.stateGateway != false && this.stateSystemNumber != false && this.stateUser != false && this.statePassword != false && this.stateClient != false && this.stateLanguage != false && this.statePoolsize != false && this.stateMaxPoolsize != false && this.stateIdleTime != false){
        if(this.sncMode){
          if(this.stateSncLib != false && this.stateSncMyName != false && this.stateSncPartnerName != false && this.stateSncQop != false && this.stateSysId != false ){
            return true
          }
          else{
            return false
          }
        }
        else{
          return true
        }
      }
    },
    getConnectionById(id){
        this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/connection/${id}?site_guid=${this.currentSite.guid}`)
            .then(response => {
              this.contexto = response.data
              this.type = response.data.type,
              this.name = response.data.name,
              this.destination = response.data.destination,
              this.server = response.data.server,
              this.gateway = response.data.gateway,
              this.systemNumber = response.data.systemNumber,
              this.user = response.data.user_sap,
              this.password = response.data.password_sap,
              this.client = response.data.client,
              this.language = response.data.language,
              this.poolSize = response.data.poolSize,
              this.maxPoolSize = response.data.maxPoolSize,
              this.idleTime = response.data.idleTime,
              this.sncMode = response.data.sncMode,
              this.sncLib = response.data.sncLib,
              this.sncMyName = response.data.sncMyName,
              this.sncPartnerName = response.data.sncPartnerName,
              this.sysId = response.data.sysId,
              this.sncQOP = response.data.sncQOP
            })
            .catch(error => this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger'))
    }, 
    createConnection(){
      this.validaCampos()
      if(this.camposValidos()){
        this.sendingForm = true
        this.$http.post(`/api/${this.$router.currentRoute.params.prefix}/connection`, 
          {
            id: this.connectionId,
            type: this.type,
            name: this.name,
            destination: this.destination,
            server: this.server,
            gateway: this.gateway,
            systemNumber: this.systemNumber,
            user: this.user,
            password: this.password,
            client: this.client,
            language: this.language,
            poolSize: this.poolSize,
            maxPoolSize: this.maxPoolSize,
            idleTime: this.idleTime,
            sncMode: this.sncMode,
            sncLib: this.sncLib,
            sncMyName: this.sncMyName,
            sncPartnerName: this.sncPartnerName,
            sysId: this.sysId,
            sncQOP: this.sncQOP,
            site_guid: this.currentSite.guid
          })
          .then(response => {
              this.sendingForm = false
              this.$emit('refetch-data')
              this.$emit('show-message')
          })
          .catch((error => {this.sendingForm = false; this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger')}))
      }
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          text: text,
          variant,
        },
      })
    },
    showPassword(){
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    }
  }
}
</script>
<style lang="scss">
.btn.loading{
  width: auto !important;
}
</style>