<template >
  <div class="justify-content-between align-items-center content-sidebar px-1 py-1 modal-bottom modal-top">
      <b-form>
          <b-row>

            <b-col md="12">
              <b-form-group
                :label="$t('IntegrationPage.Type')"
                label-for="type"
              >
                <b-form-select
                  disabled
                  v-model="typeConnection"
                  id="authentication-type"
                  :options="[
                    {text: 'REST', value: 1},  
                    {text: 'SQL Server', value: 2},  
                    {text: 'SAP (RFC)', value: 3},  
                    {text: 'Excel (.csv)', value: 4},  
                  ]"
                  @input="changeModal()"
                >
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
              :label="$t('IntegrationPage.NameConection')"
              label-for="name"
              :invalid-feedback="invalidNameState ?$t('NameInvalid') :$t('RequiredField')"
              >
                <b-form-input
                id="name"
                v-model="name"
                :placeholder="$t('TypeHere')"
                :state="stateName"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
              :label="$t('IntegrationPage.BaseURL')"
              label-for="base-uri"
              :invalid-feedback="$t('RequiredField')"
              >
                <b-form-input
                id="base-uri"
                v-model="baseUri"
                :placeholder="$t('IntegrationPage.BaseURLPlaceholder')"
                :state="stateBaseURL"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
              :label="$t('IntegrationPage.Authentication')"
              label-for="authentication-type"
              :invalid-feedback="$t('RequiredField')"
              >
                <b-form-select
                v-model="authentication"
                :options="[{text: $t('None'), value: 'None'}, {text: $t('API Key'), value: 'API Key'}, {text: $t('Basic'), value: 'Basic'}, {text: $t('Bearer Token'), value: 'Bearer Token'}]"
                id="authentication-type"
                @change="clearKeys"
                />
              </b-form-group>
            </b-col>

            <!-- API Key -->
            <b-col md="12">
              <b-form-group
              :label="$t('Local')"
              label-for="local"
              v-if="authentication == 'API Key'"
              :invalid-feedback="$t('RequiredField')"
              >
                <b-form-select
                v-model="local"
                :options="[{ value: null, text: 'Selecione', disabled: true },{text: 'Header', value: 'Header'}, {text: 'Query', value: 'Query'},{text: 'Body', value: 'Body'}]"
                :state="stateLocal"
                />
              </b-form-group>
            </b-col>

            <!-- Basic -->
            <b-col md="6">
                  <b-form-group
                  :label="$t('IntegrationPage.User')"
                  v-if="authentication =='Basic'"
                  label-for="user"
                  :invalid-feedback="$t('RequiredField')"
                  >
                  <b-form-input
                  v-model="user"
                  id="user"
                  :state="stateUsuario"
                  autocomplete="off"
                  onfocus="this.removeAttribute('readonly');"
                  />
                  </b-form-group>
            </b-col>
            <b-col md="6">
                  <b-form-group
                    :label="$t('IntegrationPage.Password')"
                    v-if="authentication == 'Basic'"
                    label-for="password"
                    :invalid-feedback="$t('RequiredField')"
                    >
                    <b-input-group :class="{'is-invalid': stateSenha === false}">
                        <b-form-input
                            class="border-right-0"  
                            :type="passwordType"
                            v-model="password"
                            id="password"
                            placeholder="******"
                            :state="stateSenha"
                            autocomplete="off"
                            onfocus="this.removeAttribute('readonly');" />

                        <b-input-group-append is-text style="cursor: pointer;" @click="showPassword">
                          <eye v-if="passwordType === 'password'" />
                          <eye-off v-else />
                        </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
            </b-col>
             
            <!-- Bearer Token -->
            <b-col md="6">
                <b-form-group
                :label="$t('IntegrationPage.urlAuthentication')"
                v-if="authentication == 'Bearer Token'"
                label-for="uriAuthentication"
                :invalid-feedback="$t('RequiredField')"
                >
                <b-form-input
                v-model="uriAuthentication"
                :placeholder="$t('IntegrationPage.urlAuthenticationPlaceholder')"
                :state="stateUrlAuthentication"
                />
                </b-form-group>
            </b-col>
            <b-col md="6">
                <b-form-group
                :label="$t('IntegrationPage.propertyName')"
                v-if="authentication == 'Bearer Token'"
                label-for="propertyName"
                :invalid-feedback="$t('RequiredField')"
                >
                <b-form-input
                v-model="propertyName"
                :placeholder="$t('TypeHere')"
                :state="statePropertyName"
                />
                </b-form-group>
            </b-col>
                  
            
            </b-row>
            <!-- API Key -->
            <b-row class="mb-1" v-if="authentication == 'API Key' || authentication == 'Bearer Token'">
                <b-col md="12" class="p-0 container-keys" v-for="(item, index) in authenticationList" :key="index">
                  <div class="d-flex flex-column flex-sm-row container-key">
                    <b-col class="col-12 col-sm-5 container-input-key">
                      <b-form-group
                     
                      label-for="key"
                      :invalid-feedback="$t('RequiredField')"
                      >
                        <span class="d-flex align-items-center label-key">{{$t('Key')}} <ImgEditIcon class="icon-edit-key"></ImgEditIcon></span>
                        <b-form-input
                        v-model="authenticationList[index].key"
                        :placeholder="$t('TypeHere')"
                        :state="authenticationList[index].stateKey"
                      />
                    </b-form-group>
                    </b-col>
                    <b-col class="col-12 col-sm-5 container-input-value">
                      <b-form-group
                      label-for="value"
                      :invalid-feedback="$t('RequiredField')"
                      >
                        <span class="d-flex align-items-center label-value">{{$t('Value')}} <ImgEditIcon class="icon-edit-value"/></span>
                        <b-form-input 
                        v-model="authenticationList[index].value"
                        :placeholder="$t('TypeHere')"
                        :state="authenticationList[index].stateValue"
                        />
                      </b-form-group>
                      
                    </b-col> 
                    <b-col class="d-sm-flex col-12 col-sm-2 p-0">
                      <div class=" d-flex add-row" v-if="(authenticationList.length - 1) == index ">
                        <div class="remove-bearer button-action-dinamic-data">
                          <b-button 
                          variant="primary"
                          @click="newRowAuthentication()"
                          >
                            <ImgAddIcon></ImgAddIcon>
                          </b-button>
                        </div>
                      </div> 
                      <div class=" d-flex justify-content-start remove-row pr-0">
                        <div class="remove-bearer button-action-dinamic-data">
                          <b-button 
                          @click="authenticationList.splice(index,1)"
                          v-if="authenticationList.length > 1"
                          >
                          <ImgDeleteIcon></ImgDeleteIcon>
                          </b-button>
                        </div>
                      </div> 
                    </b-col>
                  </div>
                </b-col>
            </b-row>
          <b-row>
            <b-col md="12" class="d-flex justify-content-end save-button">
              <b-form-group>
                <b-button v-if="!sendingForm"
                variant="primary"
                class="form-control"
                @click="createConnection()"
                >
                  {{ $t('IntegrationPage.Save') }}
                </b-button>

                <b-button v-if="sendingForm"
                variant="primary"
                disabled
                class="form-control loading"
                label-for="value"
                >
                  <b-spinner small />
                    {{ $t('IntegrationPage.Sending') }} ...
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
      </b-form>

  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { TheMask } from 'vue-the-mask'
import ImgDeleteIcon from '@/assets/images/pages/icon-delete.svg'
import ImgAddIcon from '@/assets/images/pages/icon-add.svg'
import ImgEditIcon from '@/assets/images/pages/edit.svg'
import Eye from '@/assets/images/icons/eye.svg'
import EyeOff  from '@/assets/images/icons/eye-off.svg'

import {
  BAvatar, BListGroup, BListGroupItem, BCardText, BForm, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BButton, BSpinner, BRow, BCol, BFormTextarea, BBadge, BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormTextarea,
    BBadge,
    ImgAddIcon,
    ImgDeleteIcon,
    ImgEditIcon,
    TheMask,
    flatPickr,
    ToastificationContent,
    BInputGroup, 
    BInputGroupAppend,
    Eye,
    EyeOff
  },
  directives: {
    Ripple,
  },
  props:{
    connectionId: {
      type: String,
    },
    listagemContexto: {
      type: Array,
    },
  },
  
  mounted() {
    this.getConnectionById(this.connectionId)
  },
  data() {
    return {
      currentSite: JSON.parse(localStorage.getItem('currentSite')),
      type: 'REST',
      name: null,
      baseUri: null,
      authentication: 'None',
      sendingForm: false,
      user: null,
      password: null,
      local: '',
      key: '',
      value: '',
      uriAuthentication: null,
      propertyName:null,
      typeConnection: 1,
      authenticationList:[
        {
          key:'',
          value:'',
          stateKey:null,
          stateValue:null
        }
      ],
      stateName:null,
      stateBaseURL:null,
      stateLocal:null,
      stateUsuario:null,
      stateSenha:null,
      stateUrlAuthentication:null,
      statePropertyName:null,
      invalidNameState:null,
      contexto:[],
      passwordType: 'password'
    }
  },
  watch:{
    authentication(){
      console.log(this.authentication)
    }
  },
  methods: {
    validaNomeFonte(){
      if(this.name != this.contexto.name){
        let fonteExistente = this.listagemContexto.filter(item => item.name == this.name && item.type_id == "5")
        if (fonteExistente.length > 0){
          return true
        }
        return false
      }
    },
    validaCampos(){
      this.stateName = this.name && !this.validaNomeFonte() ? null : false
      this.invalidNameState = this.validaNomeFonte()
      this.stateBaseURL = this.baseUri ? null : false

      if(this.authentication == 'Basic'){
        this.stateUsuario = this.user ? null : false
        this.stateSenha = this.password ? null : false
      }
      else if(this.authentication == 'API Key'){
        this.stateLocal = this.local ? null : false
        this.authenticationList.map(item => {
          if(item.key == ''){
            item.stateKey = false
          }
          else{
            item.stateKey = null
          }
          if(item.value == ''){
            item.stateValue = false
          }
          else{
            item.stateValue = null
          }
        })
      }
      else if(this.authentication == 'Bearer Token'){
        this.stateUrlAuthentication = this.uriAuthentication ? null : false
        this.statePropertyName = this.propertyName ? null : false
        this.authenticationList.map(item => {
          if(item.key == ''){
            item.stateKey = false
          }
          else{
            item.stateKey = null
          }
          if(item.value == ''){
            item.stateValue = false
          }
          else{
            item.stateValue = null
          }
        })
      }
    },
    camposValidos(){
      if(this.authentication == 'Basic' && this.stateUsuario != false && this.stateSenha != false && this.stateName != false && this.stateBaseURL != false){
          return true
      }
      else if (this.authentication == 'API Key' && this.stateLocal != false && this.stateName != false && this.stateBaseURL != false){
        let credenciaisValidas = []
        this.authenticationList.map(item => {
          if (item.stateKey != false && item.stateValue != false) {
            credenciaisValidas.push ('true')
          }
          else{
            credenciaisValidas.push ('false')
          }
        })
        if (!credenciaisValidas.includes('false')){
          return true
        }
      }
      else if(this.authentication == 'Bearer Token' && this.stateUrlAuthentication != false && this.statePropertyName !=  false && this.stateName != false && this.stateBaseURL != false ){
        let credenciaisValidas = []
        this.authenticationList.map(item => {
          if (item.stateKey != false && item.stateValue != false) {
            credenciaisValidas.push ('true')
          }
          else{
            credenciaisValidas.push ('false')
          }
        })
        if (!credenciaisValidas.includes('false')){
          return true
        }
      }
      else if(this.authentication == 'None' && this.stateName != false && this.stateBaseURL != false){
        return true
      }
      else {
        return false
      }
    },
    getConnectionById(id){
        this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/connection/${id}?site_guid=${this.currentSite.guid}`)
            .then(response => {
                this.contexto = response.data
                this.name = response.data.name
                this.baseUri = response.data.baseURL
                this.authentication = response.data.authType == 'BearerToken' ? 'Bearer Token' : response.data.authType == 'ApiKey' ? 'API Key' : response.data.authType 
                this.user = response.data.userName
                this.password = response.data.password
                this.local = response.data.local
                this.uriAuthentication = response.data.authURI
                this.propertyName = response.data.tokenProperty
                this.authenticationList = response.data.is_authentication ? JSON.parse(response.data.is_authentication) : [
                  {
                    key:'',
                    value:'',
                    stateKey:null,
                    stateValue:null
                  }
                ]

            })
            .catch(error => this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger'))
    },  
     createConnection(){
      this.validaCampos()
      if(this.camposValidos()){
        this.sendingForm = true
        this.$http.post(`/api/${this.$router.currentRoute.params.prefix}/connection`, 
        {
          id: this.connectionId,
          type: this.type,
          name: this.name,
          baseURL: this.baseUri,
          authType: this.authentication == 'Bearer Token' ? 'BearerToken' : this.authentication == 'API Key' ? 'ApiKey' : this.authentication ,
          userName: this.user,
          password: this.password ,
          local: this.local == 'Query' ? 'QueryParam' : this.local,
          authURI: this.uriAuthentication,
          tokenProperty: this.propertyName,
          is_authentication: this.authenticationList,
          site_guid: this.currentSite.guid
        })
          .then(response => {
              this.sendingForm = false
              this.$emit('refetch-data')
              this.$emit('show-message')
          })
          .catch((error => {this.sendingForm = false; this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger')}))
      }
    },
    changeModal(){
      this.$emit('changeModal', this.typeConnection)
    },
    newRowAuthentication(){
      this.authenticationList.push({key: '', value: ''})
    },
    
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          text: text,
          variant,
        },
      })
    },
    showPassword(){
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    },
    clearKeys() {
      this.authenticationList = [{
        key:'',
        value:'',
        stateKey:null,
        stateValue:null
      }];
    }
  }
}
</script>
<style scoped>
  
.button-action-dinamic-data{
  margin-top: 23px;
}
.label-key,
.label-value {
  margin-bottom: 3px;
  color: #5e5873;
  font-size: 0.857rem;
}

.icon-edit-key,
.icon-edit-value {
  margin-left: 7px;
}

.btn.loading{
  width: auto !important;
}
.is-invalid{
  background-image: none !important;
}
</style>