<template >
  <div class="justify-content-between align-items-center content-sidebar px-1 py-1 modal-bottom modal-top">
        <b-form>
            <b-row>

                <b-col md="12">
                  <b-form-group
                  :label="$t('IntegrationPage.Type')"
                  label-for="type"
                  >
                  <b-form-select
                  v-model="typeConnection"
                  id="authentication-type"
                  :options="listagemTiposConexoes"
                  @input="changeModal()"
                  >
                  </b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                    <b-form-group
                    :label="$t('IntegrationPage.NameConection')"
                    label-for="name"
                    :invalid-feedback="invalidNameState ?$t('NameInvalid') :$t('RequiredField')"
                    >
                    <b-form-input
                    id="name"
                    v-model="name"
                    :placeholder="$t('TypeHere')"
                    :state="stateName"
                    />
                    </b-form-group>
                </b-col>

                <b-col md="12" class="d-flex justify-content-end mb-0 save-button">
                    <b-form-group>
                        <b-button v-if="!sendingForm"
                        variant="primary"
                        class="form-control"
                        @click="createConnection()"
                        >
                        {{ $t('IntegrationPage.Save') }}
                        </b-button>

                    <b-button v-if="sendingForm"
                        variant="primary"
                        disabled
                        class="form-control loading"
                    >
                        <b-spinner small />
                        {{ $t('IntegrationPage.Sending') }} ...
                    </b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>

  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { TheMask } from 'vue-the-mask'


import {
  BAvatar, BListGroup, BListGroupItem, BCardText, BDropdown, BDropdownItem, BForm, BFormGroup, BFormInput, BFormSelect, BFormSelectOption, BButton, BSpinner, BRow, BCol, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BButton,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BFormTextarea,
    TheMask,
    flatPickr,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props:{
    listagemContexto: {
      type: Array,
    },
  },
  data() {
    return {
      currentSite: JSON.parse(localStorage.getItem('currentSite')),
      type: 'CSV',
      name: '',
      stateName:null,
      invalidNameState:null,
      sendingForm: false,
      typeConnection: 4,
      listagemTiposConexoes: [
        {text: 'REST', value: 1},  
        {text: 'SQL Server', value: 2},  
        {text: 'SAP (RFC)', value: 3},  
        {text: 'Excel (.csv)', value: 4}, 
        { text: 'Azure Data Factory', value: 5 } 
      ]
    }
  },
  methods: {
    validaNomeFonte(){
      let fonteExistente = this.listagemContexto.filter(item => item.name == this.name && item.type_id == "4")
      if (fonteExistente.length > 0){
        return true
      }
      return false
    },
    validaCampos(){
      this.stateName = this.name && !this.validaNomeFonte() ? null : false
      this.invalidNameState = this.validaNomeFonte()
    },
    createConnection(){
      this.validaCampos()
      if(this.stateName != false){
        this.sendingForm = true
        this.$http.post(`/api/${this.$router.currentRoute.params.prefix}/connection`, {id: -1, type: this.type,name: this.name, site_guid: this.currentSite.guid})
          .then(response => {
              this.sendingForm = false
              this.$emit('refetch-data')
              this.$emit('show-message')
              this.showToast(this.$t('IntegrationPage.success'), 'SmileIcon',  this.$t('IntegrationPage.createConectionSuccess'), 'success')
          })
          .catch((error => {this.sendingForm = false; this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger')}))
      }
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          text: text,
          variant,
        },
        
      })
    },
    changeModal(){
      this.$emit('changeModal', this.typeConnection)
    },
  }
}
</script>
<style lang="scss">
.btn.loading{
  width: auto !important;
}
</style>